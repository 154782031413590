// import { default as Typist } from 'react-typist'
import { default as styled, keyframes } from 'styled-components'
import { default as ReactDOM } from 'react-dom'
// import { default as useMouse } from './useMouse'
import { default as particlesConfig } from './particlesConfig'
import { useSpring, animated } from 'react-spring/hooks'
import { default as React, useState, useRef, useCallback, useEffect } from 'react'
import { default as ReactParticles } from 'react-particles-js';
import { default as AnimateHeight } from 'react-animate-height'

import texts from './texts'
import BackImage from './back.jpg'
import './styles.css'

const ctaUrl = 'https://www.psychicworld.com/reading-cards?aff=PJW1J'

const noop = () => {}

const images = Object.keys(texts)

const randomItem = (xs) => (
  xs[Math.floor(Math.random() * (xs.length - 1))]
)

const titles = [
  ['Past', 'Present', 'Future'],
  ['The nature of your problem', 'The cause', 'The solution'],
  ['Current situation', 'Obstacle', 'Advice'],
  ['Situation', 'Action', 'Outcome'],
  ['Context of the situation', 'Where you need to focus', 'Outcome'],
  ['What I think about the situation', 'What I feel', 'What I do'],
]

const adviceCTAs = [
  "What could this mean for me?",
  "What does my future hold?",
  "How does this apply to me?",
  "When can I expect change?",
  "What does the future have in store for me?",
  "Tell me more",
  "Find out more",
  "Discover your path",
  "Explore your future",
  "Explore the road ahead"
]

const Wrapper = styled.div`
  background: linear-gradient(135deg, #1C065A, #0b0127);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

const Headers = styled.div`
  padding-top: 40px;
`

const Heading = styled.h1`
  font-family: 'Merriweather', serif;
  font-size: 60px;
  color: #e0e1dc;
  margin: 0 0 0 0;
  text-shadow: 4px 4px #000;
  opacity: ${ props => props.shown ? 1 : 0 };
  height: ${ props => props.shown ? 'auto' : '0px' };
  transform: ${ props => props.shown ? 'translateY(0)' : 'translateY(50px)' };
  transition: all 1s ease-out;
  text-align: center;
`

const Text = styled.div`
  font-family: 'Merriweather', serif;
  font-size: 20px;
  color: #e0e1dc;
  opacity: .8;
`

const AdviceCTA = styled.a`
  text-decoration: underline;
  cursor: pointer;
`

const AdviceMessage = styled.span`
  &::after {
    content: ' ';
  }
`

const CTA = styled.div`
  padding: 20px;
  background: #280b7b;
  font-family: 'Merriweather', serif;
  z-index: 2;
  cursor: pointer;
  font-size: 32px;
  margin: 20px;
  border-radius: 10px;
  color: #e0e1dc;
  transition: all .3s ease-out;
  
  &:hover {
    background: #320e9a;
    
  }
`

const Advices = styled.div`
  font-family: 'Merriweather', serif;
  font-size: 20px;
  color: #e0e1dc;
  opacity: .8;
  width: 50%;
  text-align: left;
`

const MoveBackground = keyframes`
  from {
    opacity: 0;
    background-position: -300px 0;
    background-color: rgba(255, 255, 255, .0);
  }
  to {
    background-position: 0px 0px;
    opacity: 1;
    background-color: rgba(255, 255, 255, 1);
  }
`

const Title = styled.div`
  font-size: 40px;
`

const Advice = styled.div`
  margin-bottom: 20px;
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  transition: background-color 2s;
  background-color: rgba(255, 255, 255, ${ props => props.isFirst? 1 : .3 });
  background-repeat: no-repeat;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .7) 50%,
    rgba(255, 255, 255, .0) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  animation: ${ MoveBackground } 3s;
`


const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 900px;
  flex-wrap: wrap;
`

const Container = animated(styled.div`
  box-shadow: 0px 5px 10px -7px rgba(0, 0, 0, 1);
  width: 230px;
  height: 395px;
  margin: 20px;
  border: 15px solid #F0F0F0;
  transition: box-shadow 0.5s;
  will-change: transform;
  border-radius: 5px;
  cursor: ${ props => props.isFlipped ? 'default' : 'pointer' };
  transform-style: preserve-3d;
  background-color: #F0F0F0;

  &:hover {
    ${ props => !props.isFlipped &&
      `box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 1);`
    }
  }
`)

const Fill = styled.div`
  position: absolute;
  backface-visibility: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #F0F0F0;
`

const Front = styled(Fill)`
  background-image: url(${ BackImage });
  transform: translateZ(1px);
  top: 1px;
  left: 1px;
  bottom: 1px;
  right: 1px;
  border: 1px solid #000;
`

const Back = styled(Fill)`
  background-image: url(${ props => process.env.PUBLIC_URL + `/cards/${ props.image }` });
  background-position: center center;
  transform: rotateY(180deg) translateZ(1px);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const Particles = ({ children }) => {
  return (
      <ReactParticles
        params={ particlesConfig }
        style={{
          position: 'absolute',
          zIndex: 0,
          left: 0,
          right: 0,
          bottom: 0,
          top: 0
        }}
      />
  )
}

const transform = (x, y, s) => (
  `perspective(600px) rotateX(${ x }deg) rotateY(${ y }deg) scale(${ s }) translateZ(-1px)`
)

const calculate = (clientX, clientY, rect) => {
  const scrollX = (window.scrollX || window.pageXOffset || document.body.scrollLeft)
  const scrollY = (window.scrollY || window.pageYOffset || document.body.scrollTop)

  const x = clientX - rect.x - scrollX
  const y = clientY - rect.y - scrollY

  const dampen = 17

  return [
    -(y - rect.h / 2) / dampen,
    (x - rect.w / 2) / dampen,
    1.117
  ]
}

const useRect = (ref) => {
  const [rect, setRect] = useState({ x: 0, y: 0, w: 0, h: 0 })

  useEffect(() => {
    if (!ref.current) return

    const rect = ref.current.getBoundingClientRect()

    setRect({
      x: rect.left,
      y: rect.top,
      w: rect.width,
      h: rect.height,
    })
  }, [ref])

  return rect
}

const Card = ({ data, nextStep, ...props }) => {
  const ref = useRef(null)
  const front = useRef(null)
  const rect = useRect(front)

  const [isFlipped, setIsFlipped] = useState(false)

  const [state, setState] = useSpring(() => ({
    xys: [0, 0, 1],
    config: {
      mass: 5,
      tension: 350,
      friction: 40
    }
  }))

  const onClick = useCallback(e => {
    setIsFlipped(!isFlipped)
    setState({ xys: [0, 180, 1] })
    nextStep()
  })

  const onMouseLeave = useCallback(e => (
    setState({ xys: [0, 0, 1] })
  ))

  const onMouseMove = useCallback(({ clientX: x, clientY: y }) => (
    setState({ xys: calculate(x, y, rect) })
  ))

  return (
    <Container
      isFlipped={ isFlipped }
      onClick={ isFlipped ? noop : onClick }
      onMouseMove={ isFlipped ? noop : onMouseMove }
      onMouseLeave={ isFlipped ? noop : onMouseLeave }
      style={{
        transform: state.xys.interpolate(transform)
      }}
    >
      <Front ref={ front } />
      <Back image={ data.image } />
    </Container>
  )
}

const usedImages = []

const randomStep = () => {
  const image = randomItem(images)

  if (usedImages.includes(image)) {
    return randomStep()
  }

  usedImages.push(image)

  return {
    image: image,
    message: texts[image],
    CTA: randomItem(adviceCTAs)
  }
}

const steps = {
  0: {
    title: titles[0][0],
    ...randomStep(),
  },
  1: {
    title: titles[0][1],
    ...randomStep(),
  },
  2: {
    title: titles[0][2],
    ...randomStep(),
  },
}

const advices = Object.keys(steps).reverse().map(key => steps[key])

const Deck = () => {
  const [step, setStep] = useState(0)
  const [showAdvices, setShowAdvice] = useState(0)
  // const [advices, setAdvices] = useState([])


  const nextStep = useCallback(() => {
    // setAdvices([steps[step], ...advices])
    setShowAdvice(showAdvices + 1)

    if (step < 2) {
      setStep(step + 1)
    }
  }, [step])

  return (
    <Wrapper>
     <Particles />
      <Headers>
        <Heading shown={ step === 0 }>
          { steps[0].title }
        </Heading>
        <Heading shown={ step === 1 }>
          { steps[1].title }
        </Heading>
        <Heading shown={ step === 2 }>
          { steps[2].title }
        </Heading>
      </Headers>
      <Text>
        { 'Pick your card' }
      </Text>
      <Grid>
        <Card nextStep={ nextStep } data={ steps[0] } />
        <Card nextStep={ nextStep } data={ steps[1] } />
        <Card nextStep={ nextStep } data={ steps[2] } />
      </Grid>
      <CTA
        onClick={() => {
          window.gtag('event', 'conversion', {
            'event_category': 'psychicworld',
            'event_label': `tarot`,
            'value': 1,
            'send_to': 'UA-135929033-1'
          })
          window.open(ctaUrl, '_blank')
        }}
      >
        Get a detailed reading!
      </CTA>

      <Advices>
        {
          advices.map(({ title, message, CTA }, index) => (
            <AnimateHeight
              key={ title }
              duration={ 500 }
              height={ advices.length - index - 1 >= showAdvices ? 0 : 'auto' }
            >
              <Advice isFirst={ advices.length - index === showAdvices }>
                <Title>
                  { title }
                </Title>
                <AdviceMessage>{ message }</AdviceMessage>
                <AdviceCTA onClick={() => {
                  window.gtag('event', 'conversion', {
                    'event_category': 'psychicworld',
                    'event_label': `tarot advice ${CTA}`,
                    'value': 1,
                    'send_to': 'UA-135929033-1'
                  })
                  window.open(ctaUrl, '_blank')
                }}>{ CTA }</AdviceCTA>
              </Advice>
            </AnimateHeight>
          ))
        }
      </Advices>
    </Wrapper>
  )
}

ReactDOM.render(<Deck />, document.getElementById('tarot-widget'))

// Inject analytics
const analytics = document.createElement('script')
analytics.async = true
analytics.src = 'https://www.googletagmanager.com/gtag/js?id=UA-135929033-1'
document.body.appendChild(analytics)

window.dataLayer = window.dataLayer || []
window.gtag = function(){window.dataLayer.push(arguments);}
window.gtag('js', new Date());
window.gtag('config', 'UA-135929033-1', {
  'page_title' : 'clairvoyant-tarot-widget',
});
window.gtag('set', {'content_group1': 'tarot-widget'});
